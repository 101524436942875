import React, { Fragment, Component } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from '../assets/images/logo.svg'
import { NavLink } from "react-router-dom";
import { AiOutlineInstagram, AiOutlineTwitter, FiFacebook, AiOutlineYoutube } from "react-icons/all";
import axios from "axios";
class TopNavBar extends Component {

    constructor() {
        super();
        this.state = {
            facebookLink: "",
            twitterLink: "",
            instagramLink: "",
            youtubeLink: ""
        }
    }

    componentDidMount() {

        // Link facebook
        axios.get("data/facebook-link.txt").then((res) => {
            this.setState({ facebookLink: res.data })
        })

        // Link twitter
        axios.get("data/twitter-link.txt").then((res) => {
            this.setState({ twitterLink: res.data })
        })

        // Link Instagram
        axios.get("data/instagram-link.txt").then((res) => {
            this.setState({ instagramLink: res.data })
        })

          // Link Youtube
          axios.get("data/youtube-link.txt").then((res) => {
            this.setState({ youtubeLink: res.data })
        })
    }


    render() {
        return (
            <Fragment>
                <Navbar variant="dark" expand="lg">
                    <Container>
                        <Navbar.Brand> <NavLink className="nav-link" to="/"> <img alt="nav-logo" className="nav-logo" src={Logo} /></NavLink></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavLink exact activeClassName="nav-link-active" className="nav-link" to="/">
                                    Home
                                </NavLink>

                                <NavLink exact activeClassName="nav-link-active" className="nav-link" to="/About">
                                    Sobre
                                </NavLink>

                                <NavLink exact activeClassName="nav-link-active" className="nav-link" to="/Schedule">
                                    Programação
                                </NavLink>
                            </Nav>
                            <div className="d-flex">

                                {/* ICONE FACEBOOK */}

                                <a target="_blank" className="nav-link-social" href={this.state.facebookLink}>
                                    <FiFacebook />
                                </a>

                                {/* ICONE Twitter */}

                                {/* <a target="_blank" className="nav-link-social" href={this.state.twitterLink}>
                                    <AiOutlineTwitter />
                                </a> */}

                                {/* ICONE Instagram */}

                                <a target="_blank" className="nav-link-social" href={this.state.instagramLink}>
                                    <AiOutlineInstagram />
                                </a>

                                {/* ICONE Youtube */}

                                <a target="_blank" className="nav-link-social" href={this.state.youtubeLink}>
                                    <AiOutlineYoutube />
                                </a>

                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Fragment>
        );
    }
}

export default TopNavBar;